// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-games-space-fuel-js": () => import("./../../../src/pages/games/space-fuel.js" /* webpackChunkName: "component---src-pages-games-space-fuel-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-newsletter-confirm-js": () => import("./../../../src/pages/newsletter-confirm.js" /* webpackChunkName: "component---src-pages-newsletter-confirm-js" */),
  "component---src-pages-newsletter-js": () => import("./../../../src/pages/newsletter.js" /* webpackChunkName: "component---src-pages-newsletter-js" */),
  "component---src-pages-newsletter-success-js": () => import("./../../../src/pages/newsletter-success.js" /* webpackChunkName: "component---src-pages-newsletter-success-js" */),
  "component---src-pages-privacy-js": () => import("./../../../src/pages/privacy.js" /* webpackChunkName: "component---src-pages-privacy-js" */)
}

